// import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import Portal from 'react-overlays/Portal';
import { MdOutlineClose } from 'react-icons/md';

import DynamicIcon from '@/elements/DynamicIcon.js';

export default function Modal(props) {
    return (
        <Portal>

            <div className='fixed top-0 left-0 w-full h-full flex flex-col items-center justify-center p-8 backdrop-filter backdrop-blur-sm' style={{ zIndex: 10000 }}>

                <div className='fixed top-0 left-0 w-full h-full bg-black opacity-50' onClick={() => props.onClose()} />

                <div className={'w-full max-h-full flex flex-col items-center justify-center z-10 ' + (props.sizeClassName ? props.sizeClassName : 'max-w-lg')}>

                    <div className={'w-full h-full flex flex-col shadow-3xl overflow-auto ' + (props.colorClassName ? props.colorClassName : 'bg-white') + ' ' + (props.className || '')}>

                        {props.children}

                    </div>

                    {!props.disableCloseButton && <button className={'lg:fixed w-full lg:w-10 lg:h-10 p-4 lg:p-0 bg-tv-red hover:bg-tv-purple transition top-0 right-0 flex items-center justify-center text-white mt-4 lg:m-8 ' + (props.sizeClassName ? props.sizeClassName : 'max-w-lg')} onClick={() => props.onClose()}>
                        <MdOutlineClose />
                    </button>}

                </div>

            </div>

        </Portal>
    );
}

// export default function Modal(props) {
//     return (
//         <ModalBackground onBackgroundClick={() => props.onClose()}>

//             <motion.div
//                 className={'w-full flex flex-col max-w-md p-4 max-h-screen overflow-hidden '+ (props.containerClassName || '')}
//                 initial={{ opacity: 0, scale: 0 }}
//                 animate={{ opacity: 1, scale: 1 }}>

//                 <div className={'flex flex-col flex-1 overflow-hidden bg-white dark:bg-tv-gray-darkest shadow-xl ' + (props.className || '')}>
//                     {props.children}
//                 </div>

//             </motion.div>

//         </ModalBackground>
//     );
// }

// export function ModalBackground(props) {
//     return (
//         <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} className='fixed top-0 left-0 z-1000 w-full h-full'>
//             <div className='relative w-full h-full flex items-center justify-center'>
//                 <div className='absolute top-0 left-0 w-full h-full bg-opacity-60 bg-black backdrop-filter backdrop-grayscale-50 z-0' onClick={() => props.onBackgroundClick()} />
//                 <div className='flex items-center justify-center z-10'>
//                     {props.children}
//                 </div>
//             </div>
//         </motion.div>
//     );
// }

export function ModalHeader(props) {
    return (
        <div className='w-full flex flex-row px-4 sm:px-6 py-6 bg-tv-red text-white text-lg sm:text-xl font-bold shadow-xl bg-no-repeat bg-bottom sm:bg-top bg-cover' style={{ backgroundImage: 'url(/assets/images/pattern-red.png)' }}>
            <div className='flex-1'>{props.children}</div>
            {/* {!props.disableClose && <button onClick={props.onClose ? props.onClose : null} className='p-2 -mr-2 group transition hover:opacity-50'>
                <DynamicIcon className='text-lg self-center' iconName={'FaTimes'} />
            </button>} */}
        </div >
    );
}

export function ModalContent(props) {
    return (
        <div className={'flex-1 overflow-y-auto w-full flex flex-col gap-4 p-4 sm:p-6 ' + (props.className || '')}>
            {props.children}
        </div>
    );
}

export function ModalButton(props) {
    const primaryClassName = 'bg-tv-red hover:bg-tv-yellow hover:text-tv-gray-darkest'
    const secondaryClassName = 'bg-tv-purple hover:bg-tv-yellow hover:text-tv-gray-darkest'
    const baseClassName = 'flex flex-row gap-2 items-center justify-center disabled:bg-tv-gray-dark dark:bg-tv-purple transition dark:hover:bg-tv-yellow p-4 text-white dark:hover:text-tv-gray-darkest text-sm font-semibold disabled:cursor-not-allowed'

    const className = baseClassName + ' ' + (props.secondary ? secondaryClassName : primaryClassName) + ' ' + (props.className || '')

    if (props.to)
        return (<Link className={className} to={props.to} onClick={() => props.onClick()} disabled={props.disabled}><ModalButtonContent {...props} /></Link>)
    else
        return (<button className={className} onClick={() => props.onClick()} disabled={props.disabled}><ModalButtonContent {...props} /></button>);
}

function ModalButtonContent(props) {
    return (
        <>
            {props.icon && <DynamicIcon className={'text-sm'} iconName={props.icon} />}
            {props.label}
        </>
    );
};
