import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';

import DynamicIcon from '@/elements/DynamicIcon.js';
import OrbButton from '@/elements/OrbButton.js';
import DarkModeSwitch from '@/elements/DarkModeSwitch.js';
import DictionaryText from '@/elements/DictionaryText';
import ModalNewsletter from '@/elements/modal/ModalNewsletter';

import { getWebsite } from '@/utils/WebsiteUtils.js';
import { getTranslation } from '@/utils/DictionaryUtils.js';

import { getValueFromParameterMap } from '@/cms/NBossCMS.js';

const getContactData = () => {
    let dataList = [
        { 'iconName': 'FaMapMarkerAlt', 'labelKey': 'address', 'valueKey': 'address-link' },
        { 'iconName': 'FaEnvelope', 'labelKey': 'mailing-address', 'valueKey': 'address-link' },
        { 'iconName': 'FaPhone', 'labelKey': 'phone-number', 'valuePrefix': 'tel:' },
        { 'iconName': 'FaAt', 'labelKey': 'email', 'valuePrefix': 'mailto:' }
    ];

    const contactConfig = getValueFromParameterMap(getWebsite().globalContent, 'contacts');

    for (let key in dataList) {
        dataList[key]['label'] = getValueFromParameterMap(contactConfig, dataList[key]['labelKey']);
        if (dataList[key]['valueKey'])
            dataList[key]['value'] = getValueFromParameterMap(contactConfig, dataList[key]['valueKey']);
        if (dataList[key]['valuePrefix'])
            dataList[key]['value'] = dataList[key]['valuePrefix'] + dataList[key]['label'];
    }

    return dataList;
}

const getSocialData = () => {
    let dataList = [
        { 'iconName': 'FaFacebookF', 'label': 'Facebook', 'valueKey': 'facebook' },
        { 'iconName': 'FaYoutube', 'label': 'YouTube', 'valueKey': 'youtube' },
    ];

    const contactConfig = getValueFromParameterMap(getWebsite().globalContent, 'contacts');

    for (let key in dataList) {
        if (dataList[key]['valueKey'])
            dataList[key]['value'] = getValueFromParameterMap(contactConfig, dataList[key]['valueKey']);
    }

    return dataList;
}

export default function Footer(props) {
    return (
        <footer className={'w-full bg-tv-gray dark:bg-tv-yellow py-6 sm:py-12 flex justify-center ' + (props.className || '')}>

            <div className='w-full max-w-tv-boxed flex flex-col gap-6 sm:gap-6 mx-4 sm:mx-4 md:mx-4'>

                <CombinedBlock className='flex sm:hidden' />

                <div className='flex-row gap-0 sm:gap-4 md:gap-6 hidden sm:flex'>

                    <AboutBlock className='hidden md:flex' />

                    <ContactBlock className='hidden sm:flex' />

                    <ReferencesBlock className='hidden sm:flex' />

                    {/* <CurrentBlock className='hidden sm:flex' /> */}

                    <NewsletterBlock className='hidden sm:flex' />

                </div>

                <div className='flex flex-col md:flex-row items-center pt-4 border-t border-tv-gray-dark border-opacity-30 dark:border-tv-gray-darkest dark:border-opacity-30'>

                    <div className='flex-1 text-xs dark:text-sm dark:font-semibold text-center md:text-left'>{getValueFromParameterMap(getWebsite().footerContent, 'copyright')}</div>

                    <div className='flex flex-row items-center justify-center gap-2 dark:gap-4 pt-4 md:pt-0'>
                        <DarkModeSwitch className='' label labelClassName='transition text-xs dark:text-sm font-semibold' />

                        <button onClick={() => { window.scrollTo({ top: 0, behavior: 'smooth' }) }} className='flex flex-row items-center gap-2 text-tv-gray-dark dark:text-black hover:text-tv-red group'>
                            <div className='transition text-xs dark:text-sm font-semibold'><DictionaryText textKey={'to-site-top'} /></div>
                            <DynamicIcon className={'transition transform group-hover:text-tv-red-dark group-hover:scale-110'} iconName={'FaChevronCircleUp'} />
                        </button>
                    </div>

                </div>

            </div>

        </footer>
    );
}

function CombinedBlock(props) {
    return (
        <TitledBlock title={getTranslation('municipality')} className={props.className || ''}>
            <div className='flex flex-col items-center justify-center'>

                <div className='flex flex-col gap-2 mb-5 leading-relaxed items-center'>
                    {getContactData().map((item, index) => <ListItem key={index} label={item.label} iconName={item.iconName} href={item.value} />)}
                </div>

                <img className='w-28 py-2 mb-6 self-center' alt='logo' src='/assets/images/logo/shield-text-top-outline-black.svg' />

                <div className='flex flex-row'>
                    {getSocialData().map((item, index) => <SocialButton className='mx-1' key={index} label={item.label} iconName={item.iconName} url={item.value} />)}
                </div>

                <NewsletterBlock className='mt-4' disableNewsletter />

            </div>
        </TitledBlock>
    );
}

function AboutBlock(props) {
    return (
        <TitledBlock className={props.className || ''} border>

            <div className='w-full pr-2'>
                <img className='w-full block dark:hidden' alt='logo' src='/assets/images/logo/text-fill-red.svg' />
                <img className='w-full hidden dark:block' alt='logo' src='/assets/images/logo/text-fill-black.svg' />
            </div>

            <div className='text-xs dark:text-sm dark:font-semibold'>{getValueFromParameterMap(getWebsite().footerContent, 'footnote')}</div>

            <div className='flex flex-row items-center'>
                <div className='flex flex-row'>
                    {getSocialData().map((item, index) => <SocialButton className='mx-1' key={index} label={item.label} iconName={item.iconName} url={item.value} />)}
                </div>
            </div>

        </TitledBlock>
    );
}

function ContactBlock(props) {
    return (
        <TitledBlock title={getTranslation('contact')} className={props.className || ''} border>
            <ListerSubBlock>
                {getContactData().map((item, index) => <ListItem key={index} label={item.label} iconName={item.iconName} href={item.value} />)}
            </ListerSubBlock>
        </TitledBlock>
    );
}

function ReferencesBlock(props) {
    const data = getValueFromParameterMap(getWebsite().footerContent, 'references');
    return (
        <TitledBlock title={getTranslation('references')} className={props.className || ''} border>
            <ListerSubBlock>
                {(data && data.contentList.length > 0) && data.contentList.map((item, index) => (<ListItem key={index} label={getValueFromParameterMap(item, 'title')} href={getValueFromParameterMap(item, 'url')} disc />))}
            </ListerSubBlock>
        </TitledBlock>
    );
}

// function CurrentBlock(props) {
//     const data = getValueFromParameterMap(getWebsite().footerContent, 'current');
//     return (
//         <TitledBlock /*title={getTranslation('current')}*/ className={props.className || ''} border>
//             <ListerSubBlock>
//                 {(data && data.contentList.length > 0) && data.contentList.map((item, index) => (<ListItem key={index} label={getValueFromParameterMap(item, 'title')} href={getValueFromParameterMap(item, 'url')} disc />))}
//             </ListerSubBlock>
//         </TitledBlock>
//     );
// }

function NewsletterBlock(props) {
    const [modalVisible, setModalVisible] = useState(false);

    let location = useLocation();

    // const _getQueryVariable = () => {
    //     let _term = getQueryVariable(location.search, globalConfig.search.queryParam) || '';
    //     if (_term !== '')
    //         _term = decodeURIComponent(_term);
    //     return _term;
    // };

    useEffect(() => {
        if (location.search && location.search.indexOf('hirlevel') > -1)
            setModalVisible(true);
    }, [location]);

    const id = getValueFromParameterMap(getWebsite().footerContent, 'pmContactListSubscribe');

    useEffect(() => {
        if (!props.disableNewsletter) {
            const newsletterSubscribe = localStorage.getItem('tvNewsletterSubscribe');
            const cookieConsent = localStorage.getItem('tvCookieConsent');

            console.log(cookieConsent)

            let showPopup = (!newsletterSubscribe && cookieConsent) ? true : false;

            if (showPopup)
                setTimeout(() => setModalVisible(true), 5000);
        }
    }, [])

    return (
        <TitledBlock title={getTranslation('newsletter')} className={'' + props.className || ''} border>

            <div className='text-center sm:text-left mt-4 sm:mt-0 text-xs dark:text-sm dark:font-semibold tracking-wide'>{getTranslation('newsletter-description')}</div>

            <button onClick={() => setModalVisible(!modalVisible)} className='w-full bg-tv-red hover:bg-tv-yellow dark:bg-tv-purple dark:hover:bg-white p-2 sm:p-1 mt-4 sm:mt-0 text-xs dark:text-sm text-white hover:text-tv-gray-darkest transition rounded flex flex-row gap-2 items-center justify-center'>
                {/* <DynamicIcon className={'transition'} iconName={'FaEnvelope'} /> */}
                {getTranslation('newsletter-subscription')}
            </button>

            {modalVisible && <ModalNewsletter id={id} onClose={() => {
                localStorage.setItem('tvNewsletterSubscribe', Date.now());
                setModalVisible(false);
            }} />}

        </TitledBlock>
    );
}

function TitledBlock(props) {
    return (
        <div className={'flex-1 flex-col gap-4 pr-0 sm:pr-4 md:pr-6 ' + (props.border ? ' border-r border-tv-gray-dark border-opacity-30 dark:border-tv-gray-darkest dark:border-opacity-30 last:border-0 ' : '') + (props.className || '')} >
            {props.title ? <h1 className={'font-bold text-base text-center sm:text-left tracking-wide uppercase'}>{props.title}</h1> : null}
            {props.children}
        </div>
    );
}

function ListerSubBlock(props) {
    return (<div className={'flex flex-col gap-2 ' + (props.className || '')}> {props.children} </div>);
}

function ListItem(props) {
    let label = props.label;

    if (props.href)
        label = <a href={props.href}>{props.label}</a>;
    else if (props.to)
        label = <Link to={props.to}>{props.label}</Link>;
    else
        label = <div className='text-left'>{props.label}</div>;

    return (
        <div className={'text-xs dark:text-sm dark:font-semibold tracking-wide flex flex-row items-center transition ' + (props.href || props.to ? 'hover:text-tv-red group ' : '') + (props.className || '')}>
            {(props.iconName || props.disc) ? <DynamicIcon className={'mr-2 transition group-hover:text-tv-red-dark ' + (props.disc ? 'text-xxs' : 'text-xs')} iconName={props.iconName ? props.iconName : 'FaChevronRight'} /> : null}
            {label}
        </div>
    );
}

function SocialButton(props) {
    return (
        <OrbButton
            className={'w-8 dark:w-10 h-8 dark:h-10 ' + (props.className || '')}
            data={{ iconName: props.iconName, iconClassName: 'text-white', backgroundClassName: 'bg-tv-gray-darkest' }}
            href={props.url}
            target='_blank'
            title={props.label}
        />
    );
}