import { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import Modal from '@/elements/modal/Modal';
import GoogleMapReact from 'google-map-react';
import { Link } from 'react-router-dom';
import Tooltip from 'react-tooltip'

import DynamicIcon from '@/elements/DynamicIcon.js';
import { Marker, drawPolyItems } from '@/components/tv2030/Tv2030Map.js';

import MapControl from '@/services/MapControl.js';

import { getValueFromParameterMap } from '@/cms/NBossCMS.js';

import mapConfig from '@/config/mapConfig.js'

const baseCardClassName = 'bg-white rounded-md shadow-lg';
const cardPaddingClassName = 'p-4 md:p-6';
const cardClassName = baseCardClassName + ' ' + cardPaddingClassName;

export default function Tv2030Modal(props) {
    const [content, setContent] = useState(null);
    const [mapData, setMapData] = useState(null);

    const [fullIntroductionVisible, setFullIntroductionVisible] = useState(null);

    useEffect(() => {
        if (props.itemKey) MapControl.getProject(props.itemKey).then(result => { setContent(result.content); setMapData(result.mapData) });
        else { setContent(null); setMapData(null); }
    }, [props.itemKey])

    const getData = (data) => { if (data && data.length > 0) return data; return null; }
    const getDataList = (data) => { if (data && data.contentList.length > 0) return data; return null; }

    const _data = {
        title: getData(getValueFromParameterMap(content, 'title')),
        gallery: getValueFromParameterMap(content, 'gallery'),
        type: getValueFromParameterMap(content, 'type'),
        descriptionShort: getData(getValueFromParameterMap(content, 'description-short')),
        descriptionFull: getData(getValueFromParameterMap(content, 'description-full')),
        calendarList: getDataList(getValueFromParameterMap(content, 'calendar-list')),
        investmentDataList: getDataList(getValueFromParameterMap(content, 'investment-data-list')),
        milestoneList: getDataList(getValueFromParameterMap(content, 'milestone-list')),
        mapVisible: getValueFromParameterMap(content, 'map-visible'),
        technicalParameterList: getDataList(getValueFromParameterMap(content, 'technical-parameter-list')),
        tagList: getData(getValueFromParameterMap(content, 'tag-list')),
        genericSettings: getValueFromParameterMap(content, 'generic-settings'),
        documentList: getDataList(getValueFromParameterMap(content, 'document-list')),
        highlightDocumentList: getDataList(getValueFromParameterMap(content, 'highlight-document-list')),
        newsList: getData(getValueFromParameterMap(content, 'news-list')),
    }

    return (
        <Modal disableCloseButton sizeClassName='max-w-4xl' className={'relative w-full bg-tv-gray-light bg-opacity-75 rounded-lg py-4 pr-0 shadow-md overflow-hidden ' + (props.className || '')} containerClassName='w-screen max-w-screen-md' onClose={props.onClose}>

            <button className='absolute top-0 right-0 w-12 h-12 z-20 bg-tv-red sm:hover:bg-tv-purple rounded-bl-lg shadow-lg flex items-center justify-center text-white text-lg transition-colors' onClick={() => props.onClose()}>
                <DynamicIcon className={''} iconName='FaTimes' />
            </button>

            <div className='flex flex-col gap-4 overflow-y-scroll line-scrollbar ml-4 pr-2 rounded-lg'>

                <Heading title={content ? _data.title : 'Terézváros 2030'} subTitle={content ? ('Terézváros 2030 - ' + getValueFromParameterMap(_data.type, 'title')) : 'Az adatok betöltés alatt, kis türelmét kérjük'} />

                {/* <div className='relative'>

                    <svg className='invisible' viewBox='0 0 1 1' />
                    <div className='absolute top-0 left-0 w-full h-full bg-red-400'></div>

                </div> */}

                {_data.gallery && <Gallery data={_data.gallery} title={_data.title} />}

                {fullIntroductionVisible ?
                    <>
                        {_data.descriptionFull && <Introduction data={_data.descriptionFull} />}
                        <button className='w-full py-4 rounded-md bg-tv-red hover:bg-tv-purple text-white uppercase font-bold transition' onClick={() => setFullIntroductionVisible(false)}>Vissza</button>
                    </>
                    :
                    <>
                        {(_data.descriptionShort || _data.calendarList) && <div className='flex flex-col sm:flex-row gap-4'>
                            {_data.descriptionShort && <Introduction className='flex-1 min-h-full overflow-hidden' data={_data.descriptionShort} onShowFull={_data.descriptionFull ? () => setFullIntroductionVisible(true) : null} />}
                            {_data.calendarList && <Calendar className='hidden sm:block' data={_data.calendarList} />}
                        </div>}

                        {_data.investmentDataList && <InvestmentDataList data={_data.investmentDataList} />}

                        {_data.milestoneList && <MilestoneList data={_data.milestoneList} />}

                        {(_data.mapVisible || _data.technicalParameterList) && <div className={'flex gap-4 ' + (_data.technicalParameterList ? 'flex-col md:flex-row' : 'flex-row')}>
                            {_data.mapVisible && <Map className={_data.technicalParameterList ? '' : 'flex-1'} data={content} mapData={mapData} filter={props.filter} />}
                            {_data.technicalParameterList && <TechnicalParameterList className='flex-1' data={_data.technicalParameterList} />}
                        </div>}

                        {((_data.tagList || _data.documentList)) && <div className='flex flex-col md:flex-row gap-4'>
                            {_data.tagList && <TagList data={_data.tagList} list={getValueFromParameterMap(_data.genericSettings, 'tag-list').contentList} />}
                            {_data.documentList && <DocumentList className={_data.tagList ? '' : 'flex-1'} data={_data.documentList} />}
                        </div>}

                        {_data.highlightDocumentList && <div className='flex flex-col'><DocumentList data={_data.highlightDocumentList} highlight /></div>}

                        {(_data.newsList) && <NewsList data={_data.newsList} />}

                    </>
                }

            </div>

        </Modal>
    )
}

function Heading(props) {
    return (
        <div className={'w-full flex gap-4 ' + (props.className || '')}>

            <div className='hidden sm:flex rounded-md bg-tv-red w-24 p-4 items-center justify-center shadow-lg bg-no-repeat bg-bottom bg-cover' style={{ backgroundImage: 'url(/assets/images/pattern-red.png)' }}>
                <img className='w-full' alt='logo' src='/assets/images/logo/shield-text-top-fill-white.svg' />
            </div>

            <div className={'relative flex items-center rounded-md flex-1 bg-white shadow-lg overflow-hidden ' + cardPaddingClassName} >

                <div className='absolute top-0 left-0 w-full h-full bg-no-repeat bg-cover' style={{ backgroundPosition: '20% 35%', backgroundImage: 'url(/assets/images/pattern-white.png)' }} />
                <div className='absolute top-0 left-0 w-full h-full  bg-gradient-to-r from-white' />

                <div className='flex flex-col gap-2.5 sm:gap-1 ml-14 sm:ml-0 mr-0 sm:mr-12 z-10'>
                    <div className='text-2xl sm:text-4xl md:text-5xl font-bold leading-none'>{props.title}</div>
                    <div className='w-full h-px bg-tv-red' />
                    <div className='text-xxs sm:text-base sm:pt-1'>{props.subTitle}</div>
                </div>

                <OverlayFlags className='left-4 sm:left-auto right-auto sm:right-12 bg-tv-red z-10' />

            </div>

        </div>
    );
}

function Gallery(props) {
    const [index, setIndex] = useState(0);

    const buttonClassName = 'absolute w-1/2 h-full top-0 group flex p-4 items-center';
    const orbClassName = 'w-10 h-10 sm:w-12 sm:h-12 bg-tv-red-dark group-hover:bg-tv-purple shadow-xl rounded-full flex items-center justify-center transition-colors';
    const iconClassName = 'text-white text-lg sm:text-xl';

    const download = () => {
        if (index !== null) {
            let url = props.data.contentList[index].downloadUrl;
            let fileName = props.title + ' - ' + (index + 1);
            var xhr = new XMLHttpRequest();
            xhr.open("GET", url, true);
            xhr.responseType = "blob";
            xhr.onload = function () {
                var urlCreator = window.URL || window.webkitURL;
                var imageUrl = urlCreator.createObjectURL(this.response);
                var tag = document.createElement('a');
                tag.href = imageUrl;
                tag.download = fileName;
                document.body.appendChild(tag);
                tag.click();
                document.body.removeChild(tag);
            }
            xhr.send();
        }
    };

    return (
        <div /*className={baseCardClassName + ' w-full relative bg-tv-gray-light overflow-hidden ' + (props.className || '')}*/ className={baseCardClassName + ' w-full relative bg-tv-gray-light'}>

            <svg className='invisible' viewBox='0 0 16 9' />

            <div className={'absolute top-0 left-0 w-full h-full overflow-hidden rounded-md'}>

                {props.data.contentList.map((item, i) => <motion.div
                    key={item.id}
                    className='absolute w-full h-full top-0 left-0 bg-cover bg-center '
                    style={{ backgroundImage: 'url(' + item.downloadUrl + ')' }}
                    transition={{ ease: 'linear', duration: 0.5 }}
                    initial={i === index ? 'visible' : 'hidden'}
                    animate={i === index ? 'visible' : 'hidden'}
                    variants={{ 'visible': { opacity: 1 }, 'hidden': { opacity: 0 } }}>
                </motion.div>)}

                {props.data.contentList.length > 1 && <>

                    <button className={buttonClassName + ' justify-start left-0'} onClick={() => setIndex(index !== 0 ? (index - 1) : (props.data.contentList.length - 1))}>
                        <div className={orbClassName}>
                            <DynamicIcon className={iconClassName} iconName='FaChevronLeft' />
                        </div>
                    </button>

                    <button className={buttonClassName + ' justify-end right-0'} onClick={() => setIndex(index !== props.data.contentList.length - 1 ? (index + 1) : 0)}>
                        <div className={orbClassName}>
                            <DynamicIcon className={iconClassName} iconName='FaChevronRight' />
                        </div>
                    </button>

                    <button className='absolute bottom-0 right-0 w-10 h-10 sm:w-12 sm:h-12 flex items-center justify-center bg-tv-red hover:bg-tv-purple rounded-tl-lg transition-colors' onClick={() => download()}>
                        <DynamicIcon className={iconClassName} iconName='FaFileDownload' />
                    </button>

                </>}

            </div>

        </div>
    );
}

function Introduction(props) {
    return (
        <div className={'relative w-full flex flex-col gap-3 justify-between ' + cardClassName + ' ' + (props.className || '')}>

            <div className='w-full flex flex-col gap-3'>

                <CardTitle title='Projekt bemutatása' icon='/assets/images/icon/tv/yellow-dark/20.svg' />

                <div className='text-xs sm:text-sm' dangerouslySetInnerHTML={{ __html: props.data }} />

            </div>

            {props.onShowFull && <div className='w-16 h-16 absolute -bottom-8 -right-8 bg-tv-yellow transform rotate-45 flex items-center'>

                <DynamicIcon className='items-center pl-1 text-white' iconName='FaArrowRight' />

            </div>}

            {props.onShowFull && <button className='text-left uppercase font-bold text-tv-red' onClick={() => props.onShowFull()}>Tovább olvasom</button>}

        </div>
    );
}

function Calendar(props) {
    const [year, setYear] = useState(null);
    const [month, setMonth] = useState(null);
    const [days, setDays] = useState([]);

    const monthNames = ['Január', 'Február', 'Március', 'Április', 'Május', 'Június', 'Július', 'Augusztus', 'Szeptember', 'Október', 'November', 'December'];
    const dayNames = ['Hé', 'Ke', 'Sze', 'Cs', 'Pé', 'Szo', 'V'];

    useEffect(() => {
        var daysOfYear = [];
        for (var d = new Date(year, month, 1); d <= new Date(year, month + 1, 1); d.setDate(d.getDate() + 1))
            daysOfYear.push(new Date(d).getDate());
        daysOfYear.pop();
        setDays(daysOfYear);
    }, [year, month])

    useEffect(() => {
        if (props.data) {
            let dates = [];
            for (let i = 0; i < props.data.contentList.length; i++)
                dates.push(new Date(getValueFromParameterMap(props.data.contentList[i], 'date-start')));
            dates.sort(function (a, b) { return a - b; });
            // setYear(dates[0].getFullYear());
            // setMonth(dates[0].getMonth());
        } else {
            // const currentDate = new Date();
            // setYear(currentDate.getFullYear());
            // setMonth(currentDate.getMonth());
        }
        const currentDate = new Date();
        setYear(currentDate.getFullYear());
        setMonth(currentDate.getMonth());
    }, [props.data])

    const getOffset = () => { var d = new Date(year, month, 0); return d.getDay() + 1; };

    const toggleMonth = (direction) => { if (month === 0) { if (direction > 0) { setMonth(month + 1); } else { setMonth(11); setYear(year - 1); } } else if (month === 11) { if (direction > 0) { setMonth(0); setYear(year + 1); } else { setMonth(month - 1); } } else { setMonth(month + direction); } };

    const getDateElementData = (day) => {
        let dataList = [];

        let examinedDate = new Date(year, month, day);
        examinedDate.setHours(0, 0, 0, 0);

        // console.log(examinedDate);

        let currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);

        // console.log(currentDate.getDay());

        if (examinedDate.getFullYear() === currentDate.getFullYear() && examinedDate.getMonth() === currentDate.getMonth() && examinedDate.getDate() === currentDate.getDate())
            // console.log(examinedDate);
            dataList.push({ type: 'CURRENT', title: null, description: null })

        for (let i = 0; i < props.data.contentList.length; i++) {
            const element = props.data.contentList[i];

            const title = getValueFromParameterMap(element, 'title');
            const description = getValueFromParameterMap(element, 'description');

            const start = getValueFromParameterMap(element, 'date-start');
            let startDate = new Date(start);
            startDate.setHours(0, 0, 0, 0);

            const end = getValueFromParameterMap(element, 'date-end');

            if (start && !end) {
                if (examinedDate.valueOf() === startDate.valueOf())
                    dataList.push({ type: 'SINGLE', title: title, description: description });
            } else {
                let endDate = new Date(end);
                endDate.setHours(0, 0, 0, 0);

                if (examinedDate.valueOf() === startDate.valueOf())
                    dataList.push({ type: 'START', title: title, description: description })
                if (examinedDate.valueOf() === endDate.valueOf())
                    dataList.push({ type: 'END', title: title, description: description })
                if ((examinedDate.getTime() <= endDate.getTime() && examinedDate.getTime() >= startDate.getTime()))
                    dataList.push({ type: 'INTERVAL', title: title, description: description })
            }

        }

        return dataList.length > 0 ? dataList : null;
    };

    return (
        <div className={'relative overflow-hidden min-w-56 min-h-40 ' + (props.className || '')}>

            <svg className='invisible hidden sm:block' viewBox='0 0 1 1' />

            <div className={'relative sm:absolute -inset-0 w-full sm:h-full overflow-hidden p-6 sm:p-3 ' + baseCardClassName + ' ' + (props.className || '')}>

                <div className='absolute top-0 left-0 w-full h-full bg-no-repeat bg-cover bg-center opacity-50' style={{ backgroundImage: 'url(/assets/images/pattern-white.png)' }} />
                <div className='absolute top-0 left-0 w-full h-full bg-gradient-to-r from-white' />

                <div className='relative w-full sm:h-full flex flex-col gap-3 items-center text-sm z-10'>

                    <div className='w-full flex gap-3'>
                        <div className='flex-1 justify-between flex font-bold text-lg'>
                            <div>{monthNames[month]}</div>
                            <div>{year}</div>
                        </div>
                        <div className='flex gap-3 text-tv-yellow'>
                            <button onClick={() => toggleMonth(-1)}><DynamicIcon className={''} iconName={'FaChevronLeft'} /></button>
                            <button onClick={() => toggleMonth(+1)}><DynamicIcon className={''} iconName={'FaChevronRight'} /></button>
                        </div>
                    </div>

                    <div className='w-full flex flex-col gap-1'>
                        <div className='w-full grid grid-cols-7 text-tv-red-dark text-xs'>
                            {dayNames.map((item) => <div key={item} className='flex items-center justify-center font-semibold'>{item}</div>)}
                        </div>

                        <div className='w-full grid grid-cols-7'>
                            {days.map((item, index) => <CalendarDay key={index} day={item} dataList={getDateElementData(item)} offset={!index && getOffset()} />)}
                            <Tooltip />
                        </div>
                    </div>

                </div>

            </div>

        </div >
    );
}

function CalendarDay(props) {
    const isType = (type) => {
        if (props.dataList)
            for (let i = 0; i < props.dataList.length; i++) {
                // console.log(props.dataList[i]);
                if (props.dataList[i].type === type)
                    return true;
            }
        return false;
    }

    const isOnlyCurrentDate = () => {
        if (props.dataList && props.dataList.length === 1 && props.dataList[0].type === 'CURRENT')
            return true;
        return false;
    };

    return (
        <div className='relative' style={{ gridColumnStart: props.offset || 'initial' }}>

            <svg className='invisible block' viewBox='0 0 1 1' />

            <div data-tip data-for={props.dataList && ('tooltip-' + props.day)} className={'cursor-default absolute -inset-0 w-full h-full flex items-center justify-center ' + (props.className || '')}>

                {(props.dataList && !isOnlyCurrentDate()) && <Tooltip /*place="left"*/ id={'tooltip-' + props.day}>
                    <div className='flex flex-col max-w-24 gap-0.5'>
                        {props.dataList.map((item, index) => (item.type !== 'START' && item.type !== 'END') && <div key={index} className='flex flex-col items-center text-xxs text-center'>
                            <div className='font-semibold'>{item.title}</div>
                            {item.description && <div>{item.description}</div>}
                        </div>)}
                    </div>
                </Tooltip>}

                {(isType('START') || isType('END') || isType('INTERVAL')) && <div className={'absolute w-full h-5/6 py-2 bg-tv-rose opacity-50 ' + (isType('START') ? 'rounded-l-full ' : '') + (isType('END') ? 'rounded-r-full ' : '')} />}

                {(isType('START') || isType('END')) && <div className='absolute w-full h-full rounded-full bg-tv-red' />}

                {isOnlyCurrentDate() && <div className='absolute w-full h-full rounded-full bg-tv-gray-light' />}

                {/* {isType('SINGLE') && <div className='absolute w-full h-full rounded-full ring-2 ring-tv-yellow ring-inset'></div>} */}
                {isType('SINGLE') && <div className='absolute w-full h-full rounded-full bg-tv-yellow'></div>}

                <div className={'z-10 ' + (isType('CURRENT') ? 'font-bold' : '') + ' ' + ((isType('START') || isType('END')) ? 'text-white' : '')}>{props.day}</div>

            </div>

        </div>
    );
}

function Map(props) {
    const [map, setMap] = useState(null);
    const [maps, setMaps] = useState(null);

    const handleApiLoaded = (map, maps) => {
        setMap(map);
        setMaps(maps);
    }

    useEffect(() => {
        drawPolyItems(map, maps, [{ content: props.data, mapData: props.mapData }], 3, null, props.filter);
    }, [map, maps, props.mapData, props.data, props.filter]);

    return (
        <div className={'relative min-w-56 min-h-40 flex flex-col ' + (props.className || '')}>

            <svg className={'invisible hidden ' + (props.className.indexOf('flex-1') > -1 ? '' : 'block')} viewBox='0 0 1 1' />

            <div className={'w-full h-full items-center overflow-hidden ' + (props.className.indexOf('flex-1') > -1 ? 'relative ' : 'absolute -inset-0 ') + baseCardClassName + ' ' + (props.className || '')}>

                <GoogleMapReact
                    // className='min-h-40'
                    bootstrapURLKeys={{
                        key: 'AIzaSyD1luTKqk_RQjga3FZ2vTeFJhms3nDrjSg',
                        language: 'hu-HU',
                        region: 'hu',
                    }}
                    options={map => ({
                        disableDefaultUI: true,
                        rotateControl: false,
                        styles: mapConfig.styles,
                        mapTypeId: map.MapTypeId.ROADMAP,
                    })}
                    defaultCenter={{
                        lat: mapConfig.defaultCoordinates.lat,
                        lng: mapConfig.defaultCoordinates.lng,
                    }}
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                    defaultZoom={13}
                    disableDefaultUI={true}>

                    {props.mapData && props.mapData.map((mapItem, index) => (mapItem.type === 'MARKER' &&

                        <Marker data={{ content: props.data }} pointerData={mapItem} key={index} lat={mapItem.position.lat} lng={mapItem.position.lng} passive filter={props.filter} />

                    ))}

                </GoogleMapReact>

            </div>

            <OverlayFlags className='right-6 bg-tv-red z-10' />

        </div>
    );
}

function InvestmentDataList(props) {
    return (
        <div className={'w-full flex flex-col gap-3 ' + baseCardClassName + ' ' + (props.className || '')}>

            <CardTitle className={cardPaddingClassName + ' pb-0 md:pb-0'} title='Beruházás adatai' />

            <div className={'flex flex-wrap ' + cardPaddingClassName + ' pt-0 md:pt-0'}>
                {props.data && props.data.contentList.map((item) => (<div key={item.id} className='w-full md:w-1/2 text-xs sm:text-sm leading-tight p-0.5'>
                    <span className='font-semibold'>{getValueFromParameterMap(item, 'title')}: </span>
                    <span className='italic'>{getValueFromParameterMap(item, 'value')}</span>
                </div>))}
            </div>

            {/* <div className='grid grid-cols-1 md:grid-cols-2 gap-0.5'>
                {props.data && props.data.contentList.map((item) => (<div key={item.id} className='text-xs sm:text-sm leading-tight'>
                    <span className='font-semibold'>{getValueFromParameterMap(item, 'title')}: </span>
                    <span className='italic'>{getValueFromParameterMap(item, 'value')}</span>
                </div>))}
            </div> */}

        </div>
    );
}

function TechnicalParameterList(props) {
    return (
        <div className={'w-full flex flex-col gap-3 ' + cardClassName + ' ' + (props.className || '')}>

            <CardTitle title='Műszaki paraméterek' icon='/assets/images/icon/tv/yellow-dark/10.svg' />

            <div className='flex flex-col gap-0.5'>
                {props.data && props.data.contentList.map((item) => (<ul key={item.id} className='text-xs sm:text-sm leading-tight list-disc list-outside text-tv-yellow ml-4'>
                    <li className=''><span className='text-black'>{getValueFromParameterMap(item, 'parameter')}</span></li>
                </ul>))}
            </div>

        </div>
    );
}

function MilestoneList(props) {
    return (
        <div className={'w-full ' + baseCardClassName + ' px-4 md:px-6 pt-4 md:pt-6 pb-2 md:pb-3  ' + (props.className || '')}>

            <div className='overflow-x-scroll flex gap-2 line-scrollbar pb-2 md:pb-3 '>
                {props.data && props.data.contentList.map((item, index) => (<Milestone key={item.id} data={item} index={index} last={props.data.contentList.length - 1 === index} />))}
            </div>

        </div>
    );
}

function Milestone(props) {
    let _data = {
        title: getValueFromParameterMap(props.data, 'title'),
        status: getValueFromParameterMap(props.data, 'status'),
    }

    const statusMap = {
        'NOT_STARTED': {
            'orb': 'border border-tv-gray-light',
            'tag': 'border border-tv-gray-light',
            'line': '',
            'label': 'Nincs elkezdve',
            'icon': 'text-tv-gray-light',
            'iconName': 'FaHourglassStart',
        },
        'IN_PROGRESS': {
            'orb': 'bg-tv-purple',
            'tag': 'bg-tv-purple bg-opacity-20',
            'line': 'bg-tv-purple w-1/2',
            'label': 'Folyamatban',
            'icon': 'text-white',
            'iconName': 'FaUnlockAlt',
        },
        'COMPLETED': {
            'orb': 'bg-green-500',
            'tag': 'bg-green-500 bg-opacity-20',
            'line': 'w-full bg-green-500',
            'label': 'Teljesítve',
            'icon': 'text-white',
            'iconName': 'FaCheck',
        }
    };

    return (
        <div className={'flex flex-col gap-2 justify-between ' + (props.last ? '' : 'flex-1 min-w-32') + ' ' + (props.className || '')}>

            <div className='flex gap-2 justify-start items-center h-11'>
                <div className={'w-11 h-11 rounded-full shadow-xl flex items-center justify-center ' + (statusMap[_data.status]['orb'] || '')}>
                    <DynamicIcon className={'text-lg ' + (statusMap[_data.status]['icon'] || '')} iconName={statusMap[_data.status]['iconName'] || 'FaQuestion'} />
                </div>
                {!props.last && <div className='h-1 flex-1 rounded-full bg-tv-gray-light overflow-hidden'>
                    <div className={'h-full ' + (statusMap[_data.status]['line'] || '')}></div>
                </div>}
            </div>

            <div className='flex-1 flex flex-col gap-1.5 items-start justify-between'>
                <div className='flex flex-col'>
                    <div className='uppercase text-xxs text-tv-gray-dark'>Ütem #{props.index + 1}</div>
                    <div className='text-sm md:text-base font-bold w-min leading-tight'>{_data.title}</div>
                </div>
                <div className={'px-2.5 py-0.5 text-xxs rounded-full font-semibold whitespace-nowrap ' + (statusMap[_data.status]['tag'] || '')}><span className='opacity-75'>{(statusMap[_data.status]['label'] || 'Ismeretlen')}</span></div>
            </div>


        </div >
    );
}

function TagList(props) {
    const isActive = (item) => {
        let active = false;
        for (let i = 0; i < props.data.length; i++) {
            if (props.data[i].key === item.key)
                active = true;
        }
        return active;
    }

    return (
        <div className={'w-full flex flex-wrap gap-2 ' + cardClassName + ' ' + (props.className || '')}>

            {props.list && props.list.map((item) => (<Tag key={item.id} data={item} active={isActive(item)} />))}

        </div>
    );
}

function Tag(props) {
    return (
        <div className={'flex-1 text-xxs sm:text-xs py-0.5 px-2 transition-colors transform -skew-x-12 whitespace-nowrap text-center flex items-center justify-center ' + (props.active ? 'bg-tv-red text-white' : 'bg-tv-gray text-tv-gray-darker') + (props.className || '')}>

            #{getValueFromParameterMap(props.data, 'title')}

        </div>
    );
}

function DocumentList(props) {
    return (
        <div className={'relative overflow-hidden min-w-64 flex flex-col gap-3 ' + cardClassName + ' ' + (props.highlight ? 'bg-tv-purple' : '') + ' ' + (props.className || '')}>

            <CardTitle labelClassName={props.highlight ? 'text-tv-yellow  pb-1' : 'text-tv-red pb-1'} title={props.highlight ? 'Kiemelt dokumentumok' : 'Dokumentumok'} />

            <div className='flex flex-col gap-1'>
                {props.data && props.data.contentList.map((item, index) => (<ul key={index} className='text-xs sm:text-sm leading-tight list-disc list-outside text-tv-yellow ml-4'>
                    <li className=''><a href={getValueFromParameterMap(item, 'file').downloadUrl} target='_blank' className={(props.highlight ? 'text-white hover:text-tv-yellow' : 'text-black hover:text-tv-red') + ' transition'} dangerouslySetInnerHTML={{ __html: getValueFromParameterMap(item, 'title') }} >{ }</a></li>
                </ul>))}
            </div>

        </div>
    );
}

function NewsList(props) {
    return (
        <div className={'w-full flex flex-col gap-3 p-3 bg-opacity-50 shadow-inner ' + baseCardClassName + ' ' + (props.className || '')}>

            <div className='w-full rounded-md bg-tv-red p-4 items-center justify-center shadow-lg bg-no-repeat bg-bottom bg-cover' style={{ backgroundImage: 'url(/assets/images/pattern-purple.png)' }}>
                <CardTitle labelClassName='text-white' title='Kapcsolódó híreink' icon='/assets/images/icon/tv/yellow-dark/2.svg' />
            </div>

            <div className='w-full flex flex-col gap-3'>
                {props.data && props.data.map((item) => (<News key={item.id} data={item} />))}
            </div>

        </div>
    );
}

function News(props) {
    let _data = {
        creationDate: props.data && props.data.publicCreationDate,
        author: (props.data && props.data.publicAuthor) && props.data.publicAuthor.name,
        photographer: getValueFromParameterMap(props.data, 'photographer'),
        tagList: getValueFromParameterMap(props.data, 'tagList'),
        image: (getValueFromParameterMap(props.data, 'coverImage') && !props.disableImage) && getValueFromParameterMap(props.data, 'coverImage').downloadUrl,
        title: getValueFromParameterMap(props.data, 'heading'),
        subTitle: getValueFromParameterMap(props.data, 'subHeading'),
        lead: getValueFromParameterMap(props.data, 'lead'),
        url: getValueFromParameterMap(props.data, 'url'),
    }

    return (
        <div className={'w-full flex flex-col md:flex-row rounded-md shadow-lg bg-tv-red min-h-48 overflow-hidden ' + (props.className || '')}>

            {_data.image && <div className='relative flex-1 bg-cover bg-center shadow-xl min-h-48 md:min-h-full ' style={{ backgroundImage: 'url(' + _data.image + ')' }}>
                <OverlayFlags className='left-6 bg-tv-red' />
            </div>}

            <div className={'flex-1 flex flex-col justify-between gap-3 text-white ' + cardPaddingClassName}>

                <div className='flex items-center gap-2'>
                    <div className='flex-1 text-xl md:text-3xl font-bold uppercase'>{_data.title}</div>
                    <img className='h-10 w-10' alt='' src={'/assets/images/icon/tv/yellow/' + (Math.floor(Math.random() * 20) + 1) + '.svg'} />
                </div>

                <div className='flex flex-col gap-3'>

                    <div className='text-xs sm:text-sm' dangerouslySetInnerHTML={{ __html: _data.lead }} />
                    <Link to={getValueFromParameterMap(props.data, 'url')} className='text-left text-xs font-bold uppercase text-tv-yellow-light'>Tovább olvasom</Link>

                </div>

            </div>

        </div>
    );
}

function CardTitle(props) {
    return (
        <div className={'relative ' + (props.className || '')}>
            <div className={'text-lg md:text-2xl font-bold uppercase leading-none  ' + (props.icon ? 'mr-10 ' : ' ') + (props.labelClassName || 'text-tv-red pb-1')}>{props.title}</div>
            {props.icon && <img className='absolute h-full top-0 right-0' alt='' src={props.icon} />}
        </div>
    );
}

export function OverlayFlags(props) {
    return (
        <>
            <div className={'absolute bottom-0 w-10 px-1 py-2 rounded-t-md ' + (props.className || '')}><div className='w-full text-center text-xs font-semibold text-white'>2030</div></div>
            <div className={'absolute top-0 w-10 px-2 py-2 rounded-b-md ' + (props.className || '')}><img className='w-full' alt='logo' src='/assets/images/logo/shield-outline-white.svg' /></div>
        </>
    );
}