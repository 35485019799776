import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { motion } from 'framer-motion';

import Header from '@/components/header/Header.js';
import Footer from '@/components/footer/Footer.js';
import MainPage from '@/pages/MainPage.js';
import SimplePage from '@/pages/SimplePage.js';
import Error404Page from '@/pages/Error404Page.js';

import CookieConsent from '@/elements/CookieConsent';
import CookieModal from '@/elements/modal/CookieModal';

import CmsFrontendControl from '@/services/CmsFrontendControl.js';
import VisitorAccessManagement from '@/services/VisitorAccessManagement.js';

import { getWebsite, setWebsite } from '@/utils/WebsiteUtils.js';
import { setUser } from '@/utils/UserUtils.js';

export default function NBossCMSClient(props) {
    const [website, _setWebsite] = useState(null);
    const [routes, setRoutes] = useState(null);

    const pageDictionary = {
        'main-layout': { page: MainPage },
        'content-layout': { page: SimplePage, templateProps: { menu: false } },
        'menucontent-layout': { page: SimplePage, templateProps: { menu: true } },
        'hir-lista': { page: SimplePage, templateProps: { menu: true } },
        'ugyintezes-lista': { page: SimplePage, templateProps: { menu: true } },
        'keresesi-talalatok': { page: SimplePage, templateProps: { menu: false } },
        'elszallitott-jarmuvek-listaja': { page: SimplePage, templateProps: { menu: true } },
        'form': { page: SimplePage, templateProps: { menu: true } },
        'form-description': { page: SimplePage, templateProps: { menu: true } },
        'zold-terezvarosert-szavazas': { page: SimplePage, templateProps: { menu: false } },
        'terezvaros-2030': { page: SimplePage, templateProps: { menu: true } },
        '404': { page: Error404Page },
    };

    const createRoutes = (website) => {
        let _routes = [];

        if (website.mainPage)
            _routes.push({ path: '/', component: pageDictionary[website.mainPage.pageTemplate].page, pageKey: website.mainPage.pageKey });

        for (let key in website.sitemap)
            if (website.sitemap[key].pageTemplate && pageDictionary[website.sitemap[key].pageTemplate])
                _routes.push({ path: key, component: pageDictionary[website.sitemap[key].pageTemplate].page, pageKey: website.sitemap[key].pageKey, templateProps: pageDictionary[website.sitemap[key].pageTemplate].templateProps });
            else
                console.log('[Missing template] ' + (website.sitemap[key].pageTemplate ? website.sitemap[key].pageTemplate : 'No template defined.') + ' [for] ' + key);

        _routes.push({ path: '*', component: pageDictionary['404'].page, pageKey: 'egyszeru-oldal' });

        return _routes;
    }

    /* hotfix for main page lang change - szzoli */
    const replaceMissingMainPageLang = (data) => {
        if (data.currentLocale.key === 'en-GB')
            data.mainPage.pageKey = 'terezvaros-welcome'
        return data;
    };

    useEffect(() => {
        VisitorAccessManagement.getLoggedInClient().then(result => { setUser(result); });
        CmsFrontendControl.getWebsite(props.domainName, window.location.pathname).then(result => { _setWebsite(replaceMissingMainPageLang(result)) });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (website !== null) {
            setWebsite(website);
            setRoutes(createRoutes(website))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [website]);

    if (website && routes) {
        return (
            <Router>
                <div className='min-h-screen flex flex-col justify-between bg-fill-base'>

                    <div className='flex-1 flex flex-col'>

                        <Header className='z-20' />

                        <Switch>{routes.map((route, index) => (<Route key={index} exact path={route.path} render={props => (<route.component {...props} pageKey={route.pageKey} templateProps={route.templateProps || {}} />)} />))}</Switch>

                    </div>

                    <Footer className='z-10' />

                    <CookieModal />

                </div>
            </Router>
        );
    } else {
        return (
            <div className='w-full min-h-screen flex flex-col justify-center items-center gap-3'>
                <img className='w-64' alt='logo' src='/assets/images/logo/text-fill-red.svg' />
                <p className='text-sm font-bold text-tv-gray tracking-wider uppercase'>Betöltés...</p>
            </div>
        );
    }
}

export function NBossCMSPage(props) {
    useEffect(() => {
        if (checkPageLanguage(getWebsite().sitemap, window.location.pathname, getWebsite().currentLocale.key))
            CmsFrontendControl.getPage(props.pageprops.pageKey).then(result => { props.pageDataReady ? props.pageDataReady(result) : console.log('No pageDataReady callback defined.') });
        else
            window.open('/', '_self')
        // window.location.reload();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const checkPageLanguage = (sitemap, path, localeKey) => {
        const hasSitemapItem = sitemap[path] ? true : false;
        // console.log('hasSitemapItem: ' + hasSitemapItem);
        const hasLocale = hasSitemapItem && (sitemap[path] && sitemap[path].localeList.indexOf(localeKey) > -1);
        // console.log('hasLocale: ' + hasLocale);
        const isMainPage = path === '/' || path === '';
        // console.log('isMainPage: ' + isMainPage);

        return (hasLocale || isMainPage || !hasSitemapItem) ? true : false;
    }

    return (<motion.div
        className={props.className}
        // style={{ originX: 0.5, originY: 0, transformPerspective: 2000 }}
        transition={{ duration: .3 }}
        initial={{ opacity: 0/*, rotateX: 0*/ }}
        animate={{ opacity: 1/*, rotateX: 0*/ }}
        exit={{ opacity: 1/*, rotateX: 0*/ }}>
        {props.children}
    </motion.div>);
}

export const getParameterEndMatch = (data, valueKey, end) => {
    if (data && valueKey && end)
        for (let key in data.parameterMap)
            if (key.endsWith(end))
                return data.parameterMap[key][valueKey];
}

export const getValueFromParameterMap = (data, key) => {
    if (data && key && data.parameterMap[key])
        return data.parameterMap[key].value;
    return null;
}

// export const gvfpm = (data, key) => {
//     return getValueFromParameterMap(data, key);
// }

export const getNBossEndpoint = () => {
    return `http${process.env.REACT_APP_API_SECURE === 'true' ? `s` : ``}://${process.env.REACT_APP_API_HOST + (typeof process.env.REACT_APP_API_PORT !== 'undefined' ? `:${process.env.REACT_APP_API_PORT}` : ``)}/${process.env.REACT_APP_API_NAME}/methods/`;
}