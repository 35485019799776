import { useState, useEffect } from 'react';

import Modal, { ModalButton, ModalHeader, ModalContent } from '@/elements/modal/Modal';

export default function CookieModal(props) {
    const [visible, setVisible] = useState(false);
    const [settingsOpen, setSettingsOpen] = useState(false);
    const [active, setActive] = useState([]);

    const cookieName = 'tvCookieConsent';
    const cookieLink = 'https://terezvaros.hu/oldal/adatkezelesi-tajekoztato';

    const options = [
        {
            title: 'A weboldal működéséhez elengedhetetlenül szükséges sütik',
            description: 'A weboldal technikai működéséhez (pl. a grafikai tartalmak hibátlan megjelenítéséhez) feltétlenül szükségesek, ezért kikapcsolásukra nincs lehetőség, ugyanakkor semmilyen, személyes adatnak minősülő információt nem gyűjtenek.',
            key: 'MANDATORY',
            disabled: true
        },
        {
            title: 'Statisztikai sütik',
            description: 'A weboldal látogatottságának, valamint a felhasználók weboldalon kifejtett aktivitásának mérésére szolgálnak. Elősegítik a felhasználói élmény javítását, a zavartalan és megfelelő színvonalú szolgáltatásnyújtás biztosítását. A statisztikai sütikkel gyűjtött információk név nélkül kerülnek tárolásra és a látogatók viselkedésének anonim elemzésére kerülnek felhasználásra.',
            key: 'STATISTICS'
        }
    ];

    useEffect(() => {
        let mandatory = [];
        for (let i = 0; i < options.length; i++) if (options[i].disabled) mandatory.push(options[i].key);
        setActive(mandatory);

        const cookieConsent = localStorage.getItem(cookieName)
        let showPopup = cookieConsent ? false : true;
        if (showPopup) setTimeout(() => setVisible(true), 3000);
    }, []);

    const save = (keyList) => {
        const cookie = keyList.toString() + '@' + Date.now();
        localStorage.setItem(cookieName, cookie);
        setVisible(false);
    };

    const handleActiveChange = (key) => {
        let _active = [...active];
        if (active.indexOf(key) === -1) _active.push(key);
        else _active.splice(_active.indexOf(key), 1)
        setActive(_active);
    };

    const saveAll = () => {
        let keys = [];
        for (let i = 0; i < options.length; i++) keys.push(options[i].key);
        save(keys);
    };

    const saveSettings = () => {
        save(active);
    };

    if (!visible)
        return null;
    else
        return (
            <Modal className='flex flex-col flex-1 overflow-y-auto' onClose={() => setVisible(false)}>

                <ModalHeader>Adatkezelési beállítások</ModalHeader>

                <ModalContent>

                    <div className='flex flex-col gap-6 text-sm'>
                        <div className=''>Weboldalunk az alapvető működéshez szükséges cookie-kat használ. Szélesebb körű funkcionalitáshoz (marketing, statisztika, személyre szabás) egyéb cookie-kat engedélyezhet. Részletesebb információkat az Adatkezelési tájékoztatóban talál.</div>
                        <div className='text-xs'>Ha szeretne többet megtudni a sütik használatáról, <a href={cookieLink} className='font-semibold text-tv-red hover:text-tv-purple transition-colors'>olvassa el a tájékoztatónkat.</a></div>
                    </div>

                    {settingsOpen && <div className='bg-tv-purple text-white p-8 -mx-4 sm:-mx-6 flex flex-col gap-6'>
                        {options.map((item, index) => (index < 3 && <Option active={active.indexOf(item.key) > -1} onActiveChange={() => handleActiveChange(item.key)} key={item.key} data={item} />))}
                    </div>}

                    <div className='w-full flex flex-col gap-2'>
                        <ModalButton secondary label={settingsOpen ? 'Beállítások mentése' : 'Beállítások módosítása'} onClick={() => settingsOpen ? saveSettings() : setSettingsOpen(!settingsOpen)} />
                        <ModalButton label={'Mindet elfogadom'} onClick={() => saveAll()} />
                    </div>

                </ModalContent>

            </Modal>
        );
}

function Option(props) {
    return (
        <div className='flex flex-col gap-2'>

            <button className='flex items-center gap-3 md:gap-2 group text-left' onClick={() => props.onActiveChange()} disabled={props.data.disabled}>
                <Toggle active={props.active} />
                <div className='text-xs sm:text-sm font-bold'>{props.data.title}</div>
            </button>

            <div className='text-xxs'>{props.data.description}</div>

        </div>
    );
}

function Toggle(props) {
    return (
        <div className={'relative h-4 w-8 rounded-full overflow-hidden shadow-inner group-disabled:opacity-50 flex flex-col transition-colors ' + (props.active ? '' : '') + (props.disabled ? ' opcity-50' : '')}>

            <div className='w-full h-full bg-white opacity-25 top-0 left-0' />

            <div className={'absolute left-0.5 top-0.5 h-3 w-3 rounded-full shadow bg-white transition-all ' + (props.active ? 'opacity-0' : 'opacity-100')}></div>
            <div className={'absolute right-0.5 top-0.5 h-3 w-3 rounded-full shadow bg-tv-yellow transition-all ' + (props.active ? 'opacity-100' : 'opacity-0')}></div>

        </div>
    );
}